import React, {useEffect} from "react";
import {Spinner} from "react-bootstrap";

const RedirectToStorePage = () => {
    useEffect(
        () => {
            window.location.href = /android/i.test(navigator.userAgent) ? "https://play.google.com/store/apps/details?id=com.konsierge.gazprom" : "https://apps.apple.com/ru/app/%D0%B3%D0%BF%D0%B1-%D0%BA%D0%BE%D0%BD%D1%81%D1%8C%D0%B5%D1%80%D0%B6/id1455820702";
        },
        []
    );
    return <div className="align-items-center" style={{display: "flex", justifyContent: "center", width: "100vw", height: "100vh"}}>
        <Spinner animation="grow" />
    </div>;
};

export default RedirectToStorePage;
