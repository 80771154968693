import {Route, Routes, useLocation} from 'react-router-dom';
import App from '../app/app.component';
import Authorization from '../authorization/authorization.component';
import Client from '../client/client.component';
import NotFound from '../not_found/not_found.component';
import AppDownloadPage from "../app_download_page/app_download_page.component";
import RedirectToStorePage from "../redirect_page/redirect_page.component";

const Router = () => {

    const location = useLocation();

    return (
        <Routes location={location}>
            <Route path="/authorization" element={<Authorization/>} />
            <Route path="/client/*" element={<Client/>} />
            <Route path="/a_concierge" element={<AppDownloadPage name={'a_concierge'}/>} />
            <Route path="/o_concierge" element={<AppDownloadPage name={'o_concierge'}/>} />
            <Route path="/gpb_concierge" element={<AppDownloadPage name={'gpb_concierge'} />} />
            <Route path="/gpb_concierge/foreign_card" element={<RedirectToStorePage/>} />
            <Route path="/o_lifestyle" element={<AppDownloadPage name={'o_lifestyle'}/>} />
            <Route path="/" element={<App/>} />
            <Route path="*" element={<NotFound />} />
        </Routes>
    );
}

export default Router;
